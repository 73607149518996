import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { auth } from './firebaseConfig';

Vue.config.productionTip = false;

let app = null;

router.beforeEach((to, from, next) => {
  const { currentUser } = auth;
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

  if (requiresAuth && !currentUser) {
    store.dispatch('user/resetUser');
    next('/login');
  } else if (!requiresAuth && currentUser) {
    next('home');
  } else next();
});

auth.onAuthStateChanged((currentUser) => {
  store.dispatch('user/updateUserDetails', currentUser);
  if (!app) {
    app = new Vue({
      router,
      store,
      render: (h) => h(App),
    }).$mount('#app');
  }
});
