export default {
  namespaced: true,
  state: {
    currentUser: null,
  },
  actions: {
    updateUserDetails: ({ commit }, data) => {
      commit('setCurrentUser', data);
    },
    resetUser: ({ commit }) => {
      commit('setCurrentUser', null);
    },
  },
  mutations: {
    setCurrentUser: (state, data) => {
      state.currentUser = data;
    },
  },
};
