<template>
  <div class="login">
    <h2>Sign In with</h2>
    <button @click="loginGoogle">
      <img src="../assets/icons/btn_google_dark_normal_ios.svg?external" alt="Sign in with Google" />
    </button>
  </div>
</template>

<script>
import { auth, db, providerGoogle } from '../firebaseConfig';

export default {
  name: 'login',
  data() {
    return {};
  },
  methods: {
    loginGoogle() {
      auth
        .signInWithPopup(providerGoogle)
        .then((response) => {
          if (response.user && response.user.uid) {
            console.log('setting user data');
            db.ref(`users/${response.user.uid}`).set({
              displayName: response.user.displayName,
              email: response.user.email,
              photoURL: response.user.photoURL,
              uid: response.user.uid,
            });
          }
          this.$router.replace('home');
        })
        .catch((err) => {
          console.error(err.message);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.login {
  margin: 40px auto;
}
input {
  display: block;
  margin: 10px auto;
  width: 20%;
  padding: 15px;
}
button {
  background: none;
  border: 0;
  cursor: pointer;
  outline: 0;
  padding: 10px;
  width: 75px;

  img {
    width: 100%;
  }
}
p {
  margin-top: 40px;
  font-size: 14px;

  a {
    text-decoration: underline;
    cursor: pointer;
  }
}
</style>
