import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Login from '../views/Login.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/home',
    name: 'home',
    component: Home,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/host/:room?',
    name: 'host',
    component: () => import(/* webpackChunkName: "host" */ '../views/Host.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/join/:room',
    name: 'join',
    component: () => import(/* webpackChunkName: "join" */ '../views/Join.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '*',
    redirect: '/login',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    // Scroll to the top of the page on route navigation
    return { x: 0, y: 0 };
  },
  routes,
});

export default router;
