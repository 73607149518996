<template>
  <div class="home">
    <h2>Welcome {{ currentUser.displayName }}</h2>
    <p>Enter the room code to join:</p>
    <input type="text" maxlength="4" minlength="4" v-model="room" placeholder="4-Digit Room Code" />
    <button @click="joinRoom">Join</button>

    <h3>OR</h3>
    <button @click="$router.push('host')">Host a New Game</button>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Home',
  data() {
    return {
      room: '',
    };
  },
  computed: {
    ...mapState('user', ['currentUser']),
  },
  methods: {
    joinRoom() {
      if (this.room && this.room.length === 4) {
        this.$router.push({
          name: 'join',
          params: { room: this.room.toUpperCase() },
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
h2 {
  margin-top: 0;
}
input {
  text-transform: uppercase;

  &::placeholder {
    text-transform: none;
  }
}
</style>
