<template>
  <header>
    <router-link to="/home" class="logo"><Logo alt="Wimble" /></router-link>
    <!-- <nav>
      <ul>
        <li><router-link to="/">Game Library</router-link></li>
      </ul>
    </nav> -->
    <button v-if="currentUser" @click="signOut">Sign Out</button>
  </header>
</template>

<script>
import { mapState } from 'vuex';
import Logo from '../assets/icons/logo.svg';
import { auth } from '../firebaseConfig';

export default {
  name: 'AppHeader',
  components: {
    Logo,
  },
  computed: {
    ...mapState('user', ['currentUser']),
  },
  methods: {
    signOut() {
      auth
        .signOut()
        .then(() => {
          this.$router.replace('/login');
        })
        .catch((err) => {
          console.error(err.message);
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
header {
  text-align: left;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  padding: 10px 40px;
  position: relative;
  z-index: 999;

  .logo {
    border: none;
    display: inline-block;
    vertical-align: middle;

    svg {
      width: 60px;
      height: auto;
    }
  }

  nav {
    vertical-align: middle;

    li {
      display: inline-block;
      margin-right: 40px;
      vertical-align: middle;
    }

    a {
      color: #000;
      font-weight: bold;

      &.router-link-exact-active {
        color: #f00;
      }
    }
  }
}
button {
  cursor: pointer;
  background: none;
  border: 0;
  outline: 0;
  margin-left: auto;
}
</style>
